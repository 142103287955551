import './Layout.scss';

import React, { createContext, useContext, useLayoutEffect, useRef, useState } from 'react';

// eslint-disable-next-line import/no-cycle
import Cards from './Cards';
import Close from './Close';
// eslint-disable-next-line import/no-cycle
import Nav from './Nav';

const LayoutSizeCtx = createContext(null);
export const useLayoutSize = () => useContext(LayoutSizeCtx);

const Layout = ({ children }) => {
  /* Use state */
  const [layoutSize, setLayoutSize] = useState(null);
  /* Use ref */
  const layoutElem = useRef(null);

  /* Effect handling window resize */
  useLayoutEffect(() => {
    const handleResize = () => {
      // important for iOS Safari fullscreen
      // https://chanind.github.io/javascript/2019/09/28/avoid-100vh-on-mobile-web.html
      layoutElem.current.style.height = `${window.innerHeight}px`;
      layoutElem.current.style.width = `${window.innerWidth}px`;

      setLayoutSize({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  /* Return Layout */
  return (
    <LayoutSizeCtx.Provider value={layoutSize}>
      <div className="layout" ref={layoutElem}>
        <Nav />
        <Close />
        <Cards />
        <div>{children}</div>
      </div>
    </LayoutSizeCtx.Provider>
  );
};

export default Layout;
