/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import React from 'react';
import { Provider } from 'react-redux';
import { combineReducers, compose, createStore } from 'redux';

import cards from './cards';
import nav from './nav';
import page from './page';

export default ({ element }) => {
  const enhancers = [];

  if (process.env.NODE_ENV === 'development') {
    const devToolsEnhancer = window && window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
    enhancers.push(devToolsEnhancer);
  }

  const reducers = { cards, nav, page };

  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  const store = createStore(combineReducers(reducers), compose(...enhancers));
  return <Provider store={store}>{element}</Provider>;
};
