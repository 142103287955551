import { PAGE_MOUNT } from './page';

/*
 * Reducer
 */
const initState = {
  isVisible: false,
};

export default (state = initState, { payload: { page = {} } = {}, type }) => {
  switch (type) {
    case PAGE_MOUNT:
      return {
        ...state,
        isVisible: page.name === 'home',
      };

    default:
      return state;
  }
};
