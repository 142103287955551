/*
 * Types
 */
export const PAGE_MOUNT = 'page/MOUNT';
export const PAGE_WATCH_LOADED = 'page/WATCH_LOADED';

/*
 * Reducer
 */
const initState = {};

export default (state = initState, { type }) => {
  switch (type) {
    default:
      return state;
  }
};

/*
 * Actions
 */
export const pageMount = (name) => ({
  payload: { page: { name } },
  type: PAGE_MOUNT,
});

export const pageWatchLoaded = () => ({
  type: PAGE_WATCH_LOADED,
});
