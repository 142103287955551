import { PAGE_MOUNT, PAGE_WATCH_LOADED } from './page';

/*
 * Reducer
 */
const initState = {
  arePushedOutToBoarders: false,
  areFrozen: false,
  arePushedOut: false,
};

export default (state = initState, { payload: { page = {} } = {}, type }) => {
  switch (type) {
    case PAGE_MOUNT:
      return {
        ...state,
        areFrozen: page.name === 'home' ? false : state.areFrozen,
        arePushedOut: page.name === 'buy',
      };

    case PAGE_WATCH_LOADED:
      return {
        ...state,
        areFrozen: true,
      };

    default:
      return state;
  }
};
