import './styles/main.scss';

import React from 'react';
import { Helmet } from 'react-helmet';

import Layout from './components/Layout';

const App = ({ children }) => {
  return (
    <Layout>
      <Helmet>
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <title>Rule of Three</title>
      </Helmet>
      {children}
    </Layout>
  );
};

export default App;
