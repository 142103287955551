import './Nav.scss';

import { Link } from 'gatsby';
import gsap from 'gsap';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

// eslint-disable-next-line import/no-cycle
import { useLayoutSize } from './Layout';

const animateFloat = (props) => {
  const { elem, order, tl } = props;

  tl.clear().repeat(-1).yoyo(true);

  tl.fromTo(
    elem,
    {},
    {
      duration: 'random(3, 5, 0.1)',
      ease: 'power1.inOut',
      y: order % 2 === 0 ? '-=random(30, 40, 1)' : '+=random(30, 40, 1)',
    }
  );
};

const Nav = ({ navIsVisible = false }) => {
  /* Use context */
  const layoutSize = useLayoutSize();
  /* Use ref */
  const navElem = useRef(null);

  const [isHide, setIsHide] = useState(false);

  /* Handle nav btn mouse enter */
  const handleNavBtnMouseEnter = (e) => {
    const tl = gsap.timeline();

    tl.fromTo(
      e.currentTarget,
      {},
      {
        duration: 1,
        ease: 'power1.out',
        rotation: '+=720',
      }
    );
  };

  /* Effect handling nav button animation */
  useEffect(() => {
    if (!layoutSize) {
      return;
    }

    if (navIsVisible) {
      setIsHide(false);
      // setup a separate timeline for each button and run animation
      const btnTls = [];
      const navBtns = Array.from(navElem.current.children);
      navBtns.forEach((btn, idx) => {
        const tl = gsap.timeline();

        btn.classList.remove('hidden');

        const bleW = layoutSize.width / (navBtns.length * 2);
        const bleH = layoutSize.height / (navBtns.length * 2);
        const left = (2 * idx + 1) * bleW - 50;
        const top = (2 * idx + 1) * bleH - 20;
        const rotation = idx % 2 === 0 ? gsap.utils.random(-30, 0, 1) : gsap.utils.random(0, 30, 1);

        gsap.set(btn, {
          x: left,
          y: top,
        });

        tl.fromTo(
          btn,
          {
            opacity: 0.1,
            rotation: idx % 2 === 0 ? 45 : -45,
            scaleX: 0.1,
            scaleY: 0.1,
          },
          {
            delay: idx / 2,
            duration: 2.5,
            ease: 'elastic.out',
            onComplete: animateFloat,
            onCompleteParams: [{ elem: btn, order: idx, tl }],
            opacity: 1,
            rotation,
            scaleX: 1,
            scaleY: 1,
          }
        );

        btnTls.push(tl);
      });
    } else {
      const tl = gsap.timeline();
      tl.fromTo(
        navElem.current,
        {},
        {
          duration: 0.5,
          ease: 'back.in',
          onComplete: () => {
            setIsHide(true);
          },
          opacity: 0,
          scaleX: 0.1,
          scaleY: 0.1,
        }
      );
    }
  }, [layoutSize, navIsVisible]);

  if (!navIsVisible && isHide) {
    return null;
  }

  /* Return nav */
  return (
    <nav className="nav" ref={navElem}>
      <Link className="nav__btn hidden" to="/watch" onMouseEnter={handleNavBtnMouseEnter}>
        Watch
      </Link>
      <Link className="nav__btn hidden" to="/learn" onMouseEnter={handleNavBtnMouseEnter}>
        Learn
      </Link>
      <Link className="nav__btn hidden" to="/buy" onMouseEnter={handleNavBtnMouseEnter}>
        Buy
      </Link>
    </nav>
  );
};

export default connect((state) => ({
  navIsVisible: state.nav.isVisible,
}))(Nav);
