import './Cards.scss';

import cn from 'classnames';
import gsap from 'gsap';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import useIsMounted from '../utils/useIsMounted';
// eslint-disable-next-line import/no-cycle
import Card, { cardGetRandomMods } from './Card';
// eslint-disable-next-line import/no-cycle
import { useLayoutSize } from './Layout';

const intercept = (point, slope) => {
  if (slope === null) {
    // vertical line
    return point[0];
  }

  return point[1] - slope * point[0];
};

const slope = (a, b) => {
  if (a[0] === b[0]) {
    return null;
  }

  return (b[1] - a[1]) / (b[0] - a[0]);
};

const animateDrop = (args) => {
  const { containerSize, elem, isFront, isInit, order, tl } = args;
  const elemRect = elem.getBoundingClientRect();

  gsap.set(elem, {
    className: cardGetRandomMods({ isFront }),
  });

  tl.fromTo(
    elem,
    {
      rotation: order % 2 === 0 ? 'random(-45, -15, 1)' : 'random(15, 45, 1)',
      x: `random(-100, ${containerSize.width + 100}, 1)`,
      y: -elemRect.height - 125,
    },
    {
      delay: isInit && order * 2,
      duration: `random(9, 13, 0.5)`,
      ease: 'power1.in',
      onComplete: animateDrop,
      onCompleteParams: [{ ...args, isInit: false }],
      rotation: order % 2 === 0 ? 'random(15, 45, 1)' : 'random(-45, -15, 1)',
      y: containerSize.height + 250,
    }
  );
};

const animateDropTo = (args) => {
  const { containerSize, elem, order, tl } = args;

  tl.to(elem, {
    duration: `random(9, 13, 0.5)`, // TODO based on position
    ease: 'power1.in',
    onComplete: animateDrop,
    onCompleteParams: [{ ...args, isInit: false }],
    rotation: order % 2 === 0 ? 'random(15, 45, 1)' : 'random(-45, -15, 1)',
    y: containerSize.height + 250,
  });
};

const animatePushToBorders = (args) => {
  const { containerSize, elem, tl } = args;
  const elemRect = elem.getBoundingClientRect();

  const A = [elemRect.left + elemRect.width / 2, elemRect.top + elemRect.height / 2];
  const B = [containerSize.width / 2, containerSize.height / 2];

  const m = slope(A, B);
  const b = intercept(A, m);

  // check element center point vs container center point
  const isLeftSide = elemRect.left + elemRect.width / 2 < containerSize.width / 2;
  const isTopSide = elemRect.top + elemRect.height / 2 < containerSize.height / 2;
  const param = 20;

  let [x, y] = [...A];

  if (isLeftSide) {
    // TODO remove while for performance reasons
    // while (x > param && y > param) {
    //   x -= 10;
    //   y = m * x + b;
    // }
    while (isTopSide ? x > param && y > param : x > param && y < containerSize.height - param) {
      x -= 10;
      y = m * x + b;
    }
  } else {
    // TODO remove while for performance reasons
    // while (x < containerSize.width - param && y > param) {
    //   x += 10;
    //   y = m * x + b;
    // }
    while (
      isTopSide
        ? x < containerSize.width - param && y > param
        : x < containerSize.width - param && y < containerSize.height - param
    ) {
      x += 10;
      y = m * x + b;
    }
  }

  tl.to(elem, {
    duration: 1,
    ease: 'power.out',
    x: x - elemRect.width / 2,
    y: y - elemRect.height / 2,
  });
};

const animatePush = (args) => {
  const { containerSize, elem, tl } = args;
  const elemRect = elem.getBoundingClientRect();

  // check element center point vs container center point
  const isLeftSide = elemRect.left + elemRect.width / 2 < containerSize.width / 2;
  const isTopSide = elemRect.top + elemRect.height / 2 < containerSize.height / 2;

  tl.to(elem, {
    duration: 1,
    ease: 'power1.in',
    x: isLeftSide ? -elemRect.width : containerSize.width + elemRect.width,
    y: isTopSide ? -elemRect.height : containerSize.height + elemRect.height,
  });
};

const Cards = ({ cardsAreFrozen = false, cardsArePushedOut = false }) => {
  /* State */
  const [cardTls, setCardTls] = useState([]); // array of objects: { tl, elem }
  const [initialized, setInitialized] = useState(false); // array of objects: { tl, elem }

  const isMounted = useIsMounted();
  const layoutSize = useLayoutSize();

  /* Ref */
  const cardsElem = useRef(null);

  /* Handlers */
  const clear = () => {
    cardTls.forEach(({ tl }) => {
      tl.clear();
    });
  };

  const init = () => {
    const newCardTls = [];
    const cards = Array.from(cardsElem.current.children);

    cards.forEach((elem, idx) => {
      // setup a separate timeline for each card and run animation
      const tl = gsap.timeline();
      const cardElem = elem;

      // hook up with drop animation
      animateDrop({
        containerSize: layoutSize,
        elem: cardElem,
        isFront: idx < 3,
        isInit: true,
        order: idx,
        tl,
      });

      newCardTls.push({ tl, elem: cardElem });
    });

    setCardTls(newCardTls);
  };

  const kill = () => {
    cardTls.forEach(({ tl }) => {
      tl.kill();
    });
  };

  const pause = () => {
    cardTls.forEach(({ tl }) => {
      tl.pause();
    });
  };

  const pushToBorders = () => {
    cardTls.forEach(({ elem, tl }) => {
      animatePushToBorders({
        containerSize: layoutSize,
        elem,
        tl,
      });
    });
  };

  const pushOut = () => {
    cardTls.forEach(({ elem, tl }) => {
      // hook up with push animation
      animatePush({
        containerSize: layoutSize,
        elem,
        tl,
      });
    });
  };

  const resume = () => {
    cardTls.forEach(({ tl }) => {
      tl.resume();
    });
  };

  const resetFrom = () => {
    cardTls.forEach(({ elem, tl }, idx) => {
      animateDropTo({
        containerSize: layoutSize,
        elem,
        isFront: idx < 3,
        isInit: Math.random() <= 0.5,
        order: idx,
        tl,
      });
    });
  };

  const reset = () => {
    cardTls.forEach(({ elem, tl }, idx) => {
      animateDrop({
        containerSize: layoutSize,
        elem,
        isFront: idx < 3,
        isInit: Math.random() <= 0.5,
        order: idx,
        tl,
      });
    });
  };

  /* Effect handling card animation */
  useEffect(() => {
    if (!isMounted || cardsAreFrozen || cardsArePushedOut || initialized) {
      return;
    }

    kill();
    init();
    setInitialized(true);
  }, [isMounted, layoutSize, cardsAreFrozen, cardsArePushedOut, initialized]);

  /* Effect handling freeze animation */
  useEffect(() => {
    if (!isMounted) {
      return;
    }

    if (!cardsAreFrozen) {
      pause();
      clear();
      resetFrom();
      resume();
      return;
    }

    pause();
    clear();
    pushToBorders();
    resume();
  }, [isMounted, cardsAreFrozen]);

  /* Effect handling push out animation */
  useEffect(() => {
    if (!isMounted) {
      return;
    }

    if (!cardsArePushedOut) {
      pause();
      clear();
      reset();
      resume();
      return;
    }

    pause();
    clear();
    pushOut();
    resume();
  }, [isMounted, cardsArePushedOut]);

  /* Return cards */
  return (
    <React.Fragment>
      <div className={cn('cards', { 'cards--frozen': cardsAreFrozen })} ref={cardsElem}>
        <Card className="hidden" />
        <Card className="hidden" />
        <Card className="hidden" />
        <Card className="hidden" />
        <Card className="hidden" />
        <Card className="hidden" />
      </div>
    </React.Fragment>
  );
};

export default connect((state) => ({
  cardsAreFrozen: state.cards.areFrozen,
  cardsArePushedOut: state.cards.arePushedOut,
  arePushedOutToBoarders: state.cards.arePushedOutToBoarders,
}))(Cards);
