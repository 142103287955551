import './Card.scss';

import gsap from 'gsap';
import React from 'react';

import cx from '../utils/cx';

// eslint-disable-next-line import/no-cycle

const CARD_COLOR_MODS = ['blue', 'green', 'red', 'yellow'];
const CARD_NUMBER_MODS = ['one', 'two', 'three', 'four'];
const CARD_SHAPE_MODS = ['circle', 'pentagon', 'square', 'triangle'];

export const cardGetRandomMods = ({ isFront }) =>
  cx('card', [
    gsap.utils.random(CARD_COLOR_MODS),
    gsap.utils.random(CARD_NUMBER_MODS),
    gsap.utils.random(CARD_SHAPE_MODS),
    isFront && 'front',
  ]);

const Card = ({ className, mods = [] }) => {
  return (
    <div className={cx('card', mods, className)}>
      <div className="card__icon card__icon--top" />
      <div className="card__bar card__bar--1" />
      <div className="card__bar card__bar--2" />
      <div className="card__bar card__bar--3" />
      <div className="card__bar card__bar--4" />
      <div className="card__icon card__icon--bottom" />
    </div>
  );
};

export default Card;
