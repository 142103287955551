import './Close.scss';

import { Link } from 'gatsby';
import gsap from 'gsap';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import IconUrl from '../images/close-emoji@3x.png';

const Close = ({ isVisible }) => {
  const [isHide, setIsHide] = useState(true);
  const textRef = useRef(null);
  const iconRef = useRef(null);

  useEffect(() => {
    const textTL = gsap.timeline();
    const iconTL = gsap.timeline();

    if (isVisible) {
      iconTL.fromTo(
        iconRef.current,
        {
          rotate: '90deg',
          x: '-=10',
          y: '-=30',
        },
        {
          delay: 0.5,
          duration: 0.9,
          ease: 'power1.out',
          opacity: 1,
          rotate: '0',
          scale: 1,
          x: 0,
          y: 0,
        }
      );

      textTL.fromTo(
        textRef.current,
        {
          rotate: '-120deg',
          x: '+=50',
          y: '-=50',
        },
        {
          delay: 0.5,
          duration: 1.1,
          ease: 'bounce.out',
          opacity: 1,
          onComplete: () => {
            setIsHide(false);
          },
          rotate: '-28deg',
          x: 0,
          y: 0,
        }
      );
    } else if (!isHide) {
      iconTL.to(iconRef.current, {
        duration: 0.9,
        ease: 'power1.in',
        onComplete: () => {
          gsap.set(textRef.current, {
            opacity: 0,
          });
        },
        rotate: '90deg',
        scale: 0,
        x: '-=10',
        y: '-=30',
      });

      textTL.to(textRef.current, {
        duration: 1.1,
        ease: 'since.in',
        onComplete: () => {
          gsap.set(textRef.current, {
            opacity: 0,
          });
          setIsHide(true);
        },
        rotate: '-120deg',
        x: '+=50',
        y: '-=50',
      });
    }
  }, [isVisible]);

  const handleMouseEnter = () => {
    if (!isVisible) {
      return;
    }

    const tl = gsap.timeline();
    const tlText = gsap.timeline();

    tl.set(iconRef.current, { willChange: 'transform', ease: 'power1.out' });
    tl.to(iconRef.current, { transform: 'rotate(-20deg)', duration: 0.2 });
    tl.to(iconRef.current, { transform: 'rotate(20deg)', duration: 0.3 });
    tl.to(iconRef.current, { transform: 'rotate(-10deg)', duration: 0.2 });
    tl.to(iconRef.current, { transform: 'rotate(0deg)', duration: 0.2 });

    tlText.to(textRef.current, { transform: 'rotate(0deg)', duration: 0.5 });
  };

  const handleMouseLeave = () => {
    if (!isVisible) {
      return;
    }

    const tlText = gsap.timeline();
    tlText.to(textRef.current, { transform: 'rotate(-28deg)', duration: 0.5 });
  };

  if (!isVisible && isHide) {
    return null;
  }

  return (
    <Link to="/">
      <div className="close" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <div className="close__icon" ref={iconRef}>
          <img className="close__icon-img" src={IconUrl} alt="" />
        </div>
        <span className="close__text" ref={textRef}>
          Close
        </span>
      </div>
    </Link>
  );
};

export default connect((state) => ({
  isVisible: !state.nav.isVisible,
}))(Close);
